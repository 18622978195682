/* tslint:disable */
/* eslint-disable */
/**
 * Job API
 * API schema for the Job API. This API allows for submitting, monitoring and controlling jobs running in the service.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MapTaskSpecializationValues } from './map-task-specialization-values';
import { TaskDescription } from './task-description';

/**
 * This feature is experimental.
 * @export
 * @interface MapTaskSpecialization
 */
export interface MapTaskSpecialization {
  /**
   *
   * @type {TaskDescription}
   * @memberof MapTaskSpecialization
   */
  task: TaskDescription;
  /**
   *
   * @type {string}
   * @memberof MapTaskSpecialization
   */
  type: MapTaskSpecializationTypeEnum;
  /**
   *
   * @type {MapTaskSpecializationValues}
   * @memberof MapTaskSpecialization
   */
  values: MapTaskSpecializationValues;
}

/**
 * @export
 * @enum {string}
 */
export enum MapTaskSpecializationTypeEnum {
  Map = 'map',
}
